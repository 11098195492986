;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.crm')
    .controller('CRMController', CRMController)
    .constant('FREQ_SCALE', '5,15,30,60,90,180'.split(','))

  function CRMController(
    FREQ_SCALE,
    $filter,
    $mdDialog,
    $mdMedia,
    $mdSidenav,
    $scope,
    $state,
    $stateParams,
    $transitions,
    CRMService,
    CustomerService,
    StatisticService,
    UserService
  ) {
    var deregistrators = []
    $scope.$on('$destroy', () => {
      deregistrators.forEach((deregistrator) => deregistrator())
    })

    deregistrators.push(
      $transitions.onSuccess({ to: 'crm.**' }, (transition) => {
        transition.noStatFilterReset = true
        $scope.stateTransition(transition.to(), transition.params())
      })
    )

    $scope.stateTransition = function (state, params) {
      $scope.view = state.name.replace('crm.', '')
      delete $scope.stats
      if ($scope.view === 'customers') {
        // frequency
        $scope.customFilter.frequency.selected = params.frequency || undefined
        // system
        if (!params.system || params.system === '0') {
          $scope.customFilter.system.selected = $scope.customFilter.system.options.map((option) => option.csaId)
        } else {
          $scope.customFilter.system.selected = [params.system]
        }
        // type
        if (!params.type) {
          $scope.customFilter.type.selected = Object.keys($scope.customFilter.type.options)
        } else {
          $scope.customFilter.type.selected = Object.keys($scope.customFilter.type.options).filter((type) => {
            if (params.type.startsWith('-')) {
              return type !== params.type.substring(1)
            }
            return type === params.type
          })
        }
      }
    }

    $scope.childStates = $state.get().filter((state) => {
      return !state.abstract && state.name.indexOf('crm.') === 0 && (state.data || {}).title
    })

    $scope.referencePeriod = {
      selected: 'year',
      options: [
        { value: 'days', label: 'Vortage' },
        { value: 'year', label: 'Vorjahr' },
      ],
    }

    $scope.frequencySections = {
      Customers: {
        icon: 'person',
        name: 'Kunden',
        sub: {
          Lost: {
            name: 'Verloren',
            trendReverse: true,
          },
          Back: {
            name: 'Rückkehrer',
          },
        },
      },
      Orders: {
        icon: 'receipt_long',
        name: 'Bestellungen',
      },
    }

    $scope.customFilter = {
      system: {
        selected: [],
        options: [],
      },
      type: {
        selected: ['new', 'existing', 'lost', 'back'],
        options: {
          new: 'Neukunde',
          existing: 'Bestandskunde',
          lost: 'Verloren',
          back: 'Rückkehrer',
        },
      },
      frequency: {
        selected: undefined,
        options: [],
      },
      onlyLoggedIn: false,
      countOrders: {
        from: undefined,
        to: undefined,
      },
      sumTotal: {
        from: undefined,
        to: undefined,
      },
      postcodes: {
        selected: [],
        options: [],
      },
    }

    deregistrators.push(
      $scope.$watch(
        'customFilter',
        () => {
          $scope.filter.updatePagination({
            page: 1,
          })
        },
        true
      )
    )

    deregistrators.push(
      $scope.$watch('filter.customer', (customer) => {
        if (!customer) {
          return
        }
        var customerSystemAssociations = customer.customerSystemAssociations
        $scope.customFilter.system.selected = []
        $scope.customFilter.system.options = customerSystemAssociations
          .filter((csa) => csa.active)
          .map((csa) => {
            $scope.customFilter.system.selected.push(csa.customerSystemId)
            return {
              csaId: csa.customerSystemId,
              name: csa.system.origin.includes('ottomatik_') ? customer.name : csa.system.name,
            }
          })
      })
    )

    deregistrators.push(
      $scope.$watch('filter.store', (store) => {
        if (!store) {
          return
        }
        CustomerService.getDeliveryareas($scope.filter.customerId, store.storeId)
          .then((response) => {
            var postcodes = response
              .map((da) => da.postcode)
              .filter((postcode, index, arr) => arr.indexOf(postcode) === index)
            $scope.customFilter.postcodes.selected = postcodes
            $scope.customFilter.postcodes.options = postcodes
          })
          .catch(() => {
            $scope.customFilter.postcodes.selected = []
            $scope.customFilter.postcodes.options = []
          })
      })
    )

    var freqScale = angular.copy(FREQ_SCALE)
    freqScale.unshift(null)
    freqScale.push(null)
    freqScale.forEach((frequency, i, array) => {
      var value, name
      if (i === 0) {
        value = 'null'
        name = 'Erstbesteller'
      } else if (frequency) {
        var from = +(array[i - 1] || -1) + 1
        var to = +frequency
        value = from + '-' + to
        name = from + '–' + to + ' Tage'
      } else {
        var last = +array[i - 1] + 1
        value = last + '+'
        name = last + '+ Tage'
      }
      $scope.customFilter.frequency.options.push({
        value: value,
        name: name,
      })
    })

    $scope.load = function (method, params) {
      if ($scope.loading || !$scope.filter.customerId || !$scope.filter.storeId) {
        return
      }

      var dateFrom = $scope.filter.dates.from
      var dateTo = $scope.filter.dates.to

      params = params || {}
      params.customerId = $scope.filter.customerId
      params.storeId = $scope.filter.storeId
      params.dateFrom = $filter('date')(dateFrom)
      params.dateTo = $filter('date')(dateTo)

      if ($scope.view === 'overview') {
        params.referencePeriod = $scope.referencePeriod.selected
      } else if ($scope.view === 'customers') {
        params.csaId = $scope.customFilter.system.selected
        params.type = $scope.customFilter.type.selected
        params.frequency = $scope.customFilter.frequency.selected
        params.postcodes = $scope.customFilter.postcodes.selected
        params.onlyLoggedIn = $scope.customFilter.onlyLoggedIn || undefined
        params.countOrders = $scope.customFilter.countOrders
        params.sumTotal = $scope.customFilter.sumTotal
        if (!method) {
          params.page = $scope.filter.page
          params.limit = $scope.filter.limit
        }
      }

      var promise
      if (method) {
        promise = CRMService[method](params)
      } else {
        $scope.loading = true
        promise = CRMService[$scope.view](params).then((stats) => {
          $scope.stats = stats
          if ($scope.view === 'overview') {
            CRMService.addCharts(stats.current)
          }
          return stats
        })
      }
      promise.finally(() => {
        delete $scope.loading
      })
      return promise
    }

    $scope.dialogLabels = function (event) {
      $mdDialog.show({
        templateUrl: 'src/crm/views/dialog.labels.html',
        targetEvent: event,
        controller: CRMLabelController,
        controllerAs: 'dialog',
        locals: {
          customer: $scope.filter.customer,
          mailLabels: function (params) {
            return $scope.load('mail', params)
          },
          printLabels: function (params) {
            return $scope.load('print', params)
          },
          csvExport: function () {
            return $scope.load('csv')
          },
          xlsExport: function () {
            return $scope.load('xls')
          },
        },
        fullscreen: true,
      })
    }

    $scope.openCustomer = function (event, phonenumber) {
      if ($scope.loading) {
        return
      }
      $scope.loading = true
      CRMService.openCustomerDetails($scope.filter.customer, phonenumber).finally(() => {
        delete $scope.loading
      })
    }

    deregistrators.push(
      $scope.$root.$watch('isSidenavCRMOpen', (isOpen) => {
        if (!isOpen) {
          delete $scope.$root.crm
        }
      })
    )

    $scope.media = function (query) {
      return $mdMedia(query)
    }

    $scope.stateTransition($state.current, $stateParams)
  }

  function CRMLabelController($mdDialog, customer, mailLabels, printLabels, csvExport, xlsExport) {
    var dialog = this

    dialog.campaign = ''
    dialog.customer = customer

    dialog.close = function () {
      $mdDialog.hide()
    }

    dialog.mailLabels = function () {
      dialog.loading = true
      mailLabels({ campaign: dialog.campaign })
        .then((response) => {
          dialog.mailResult = response
        })
        .finally(() => {
          delete dialog.loading
        })
    }

    dialog.printLabels = function (event) {
      var printDialogCnf = {
        templateUrl: 'src/crm/views/dialog.labels.print.html',
        targetEvent: event,
        controller: function ($mdDialog, $scope, cols, rows, scale) {
          'ngInject'

          $scope.width = 210 * scale
          $scope.height = 297 * scale
          $scope.cols = cols
          $scope.rows = rows
          $scope.prefill = 0
          $scope.cancel = function () {
            $mdDialog.cancel()
          }
          $scope.hide = function () {
            $mdDialog.hide($scope.prefill)
          }
        },
        locals: {
          cols: 3,
          rows: 8,
          scale: 0.5,
        },
        multiple: true,
      }

      $mdDialog.show(printDialogCnf).then((prefill) => {
        dialog.loading = true
        printLabels({ prefill: prefill }).finally(() => {
          delete dialog.loading
        })
      })
    }

    dialog.csvExport = function () {
      dialog.loading = true
      csvExport().finally(() => {
        delete dialog.loading
      })
    }

    dialog.xlsExport = function () {
      dialog.loading = true
      xlsExport().finally(() => {
        delete dialog.loading
      })
    }
  }
})()
