;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsReportingController', StatisticsReportingController)

  function StatisticsReportingController(
    $filter,
    $mdDialog,
    $mdSidenav,
    $scope,
    helperService,
    ChartService,
    ReportingService,
    StatisticService,
    UserService
  ) {
    var vm = this
    var deregistrators = []

    vm.chartByDate = getChartByDate()
    vm.chartByHour = getChartByHour()
    vm.chartByType = getChartByType()
    vm.delete = deleteReport
    vm.edit = editReport
    vm.load = load
    vm.showDelete = UserService.hasRole('reporting-delete')
    vm.showEdit = UserService.hasRole('reporting-edit')
    vm.showSlackUrl = UserService.hasRole(['admin', 'servicecenter'])
    vm.topics = getTopicsModel()

    $scope.$on('$destroy', () => deregistrators.forEach((fn) => fn()))

    function load() {
      if (vm.loading) {
        return
      }

      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId,
        dateFrom: $filter('date')($scope.filter.dates.from),
        dateTo: $filter('date')($scope.filter.dates.to),
        limit: $scope.filter.limit,
        page: $scope.filter.page,
        type: vm.topics.selected ? vm.topics.selected.value : undefined,
      }

      vm.loading = true
      return StatisticService.reporting
        .get(params)
        .$promise.then((response) => {
          vm.reports = response.reports
          vm.countTotal = response.countTotal
          refreshCharts(response)
        })
        .finally(() => {
          vm.loading = false
        })
    }

    function deleteReport(event, report) {
      var dialog = $mdDialog
        .confirm()
        .title('Meldung #' + report.reportId)
        .htmlContent('Meldung zum Thema <b><q>' + report.type + '</q></b> löschen?')
        .ok('Ja, löschen')
        .cancel('Nein, abbrechen')
        .targetEvent(event)
      $mdDialog.show(dialog).then(() => {
        ReportingService.deleteReport(report).then(load)
      })
    }

    function editReport(event, report) {
      $scope.$root.reportingEditReport = report
      $mdSidenav('reporting').open()
    }

    function getTopicsModel() {
      var topics = {
        selected: undefined,
        options: [],
        change: () => $scope.filter.updatePagination({ page: 1 }),
      }

      deregistrators.push(
        $scope.$watch('filter.customer', (customer) => {
          topics.selected = undefined
          topics.options = customer ? ReportingService.getTopics(customer) : []
        })
      )

      return topics
    }

    function getChartByDate() {
      return {
        type: 'ColumnChart',
        data: undefined,
        options: {
          animation: {
            duration: 250,
            easing: 'out',
            startup: true,
          },
          backgroundColor: 'transparent',
          fontName: 'Roboto',
          fontSize: 14,
          height: 400,
          legend: {
            position: 'none',
          },
          title: 'Meldungen je Tag',
          titleTextStyle: {
            fontSize: 16,
          },
          hAxis: {
            format: 'd. MMM',
            minorGridlines: {
              count: 0,
            },
          },
          vAxis: {
            viewWindow: {
              min: 0,
            },
          },
        },
        show: false,
        refresh: function (statsPerDate) {
          var data = new google.visualization.DataTable()
          data.addColumn('date', 'Tag')
          data.addColumn('number', 'Meldungen')

          statsPerDate.forEach((row) => {
            var date = moment(row.date).toDate()
            date.setHours(12)
            var count = parseInt(row.count)
            data.addRow([date, count])
          })

          this.data = data
          this.show = true
        },
      }
    }

    function getChartByHour() {
      var startHour = 9
      return {
        type: 'LineChart',
        data: undefined,
        options: {
          animation: {
            duration: 250,
            easing: 'out',
            startup: true,
          },
          backgroundColor: 'transparent',
          fontName: 'Roboto',
          fontSize: 14,
          height: 400,
          legend: {
            position: 'none',
          },
          lineWidth: 4,
          pointSize: 10,
          title: 'Meldungen je Stunde',
          titleTextStyle: {
            fontSize: 16,
          },
          hAxis: {
            format: 'H:mm',
            minorGridlines: {
              count: 0,
            },
            gridlines: {
              count: 24 - startHour + 1,
            },
            viewWindow: {
              min: [startHour, 0, 0],
              max: [24, 0, 0],
            },
          },
          vAxis: {
            viewWindow: {
              min: 0,
            },
          },
        },
        show: false,
        refresh: function (statsPerHour) {
          var data = new google.visualization.DataTable()
          data.addColumn('timeofday', 'Stunde')
          data.addColumn('number', 'Meldungen')

          var startOfDay = Math.min(3, startHour)
          var rows = []
          for (var hour = startOfDay; hour < 24 + startOfDay; hour++) {
            rows.push([{ v: [hour, 30, 0], f: (hour % 24) + '–' + ((hour % 24) + 1) + ' Uhr' }, 0])
          }
          statsPerHour.forEach((row) => {
            var index = (parseInt(row.hour) - startOfDay + 24) % 24
            var count = parseInt(row.count)
            rows[index][1] = count
          })
          data.addRows(rows)

          this.data = data
          this.show = true
        },
      }
    }

    function getChartByType() {
      return {
        type: 'PieChart',
        data: undefined,
        options: {
          backgroundColor: 'transparent',
          fontName: 'Roboto',
          fontSize: 14,
          height: 400,
          is3D: true,
          legend: {
            alignment: 'center',
            pagingTextStyle: {
              color: '#0078d7',
            },
            scrollArrows: {
              activeColor: '#0078d7',
            },
            textStyle: {
              fontSize: 12,
            },
          },
          sliceVisibilityThreshold: 0,
          title: 'Meldungen je Thema',
          titleTextStyle: {
            fontSize: 16,
          },
          tooltip: {
            showColorCode: true,
          },
        },
        show: false,
        refresh: function (statsPerType) {
          var data = new google.visualization.DataTable()
          data.addColumn('string', 'Thema')
          data.addColumn('number', 'Meldungen')

          statsPerType.forEach((row) => {
            data.addRow([row.type, parseInt(row.count)])
          })
          data.sort([{ column: 1, desc: true }])

          this.data = data
          this.show = true
        },
      }
    }

    function refreshCharts(data) {
      vm.chartByDate.refresh(data.statsPerDate)
      vm.chartByHour.refresh(data.statsPerHour)
      vm.chartByType.refresh(data.statsPerType)
    }
  }
})()
