;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.files').controller('FilesExplorerController', FilesExplorerController)

  function FilesExplorerController($q, $scope, $state, $transitions, FileService) {
    var vm = this
    vm.cd = cd
    vm.download = download
    vm.refresh = refresh

    var fileListCache = {}

    // listen for folder change
    var deregisterTransitionHook = $transitions.onSuccess({ to: $state.current.name }, (transition) => {
      updateCwd(transition.params().folder)
      loadFileList()
    })

    $scope.$on('$destroy', function () {
      deregisterTransitionHook()
    })

    $scope.$on('UPDATE_UNREAD_FILES', function () {
      markUnreadFiles(vm.list)
    })

    // initial loading
    updateCwd($state.params.folder)
    loadFileList()

    function cd(event, folder) {
      var path = ''
      if (folder) {
        path = (folder.dir ? folder.dir + '/' : '') + folder.name
      }
      $state.go('.', { folder: path })
    }

    function download(event, file) {
      return FileService.download(file.dir + '/' + file.name).then(() => {
        file.unread = false
      })
    }

    function generateBreadcrumbs() {
      if (!vm.cwd) {
        return []
      }
      return vm.cwd.split('/').map((dir, i, array) => {
        if (array[i - 1]) {
          array[i] = array[i - 1] + '/' + dir
        }
        return {
          dir: array[i - 1] || '',
          name: dir,
        }
      })
    }

    function loadFileList() {
      var path = vm.cwd
      var promise = fileListCache[path] ? $q.resolve(fileListCache[path]) : loadFileListFromServer(path)
      return promise.then((list) => {
        markUnreadFiles(list)
        vm.list = list
      })
    }

    function loadFileListFromServer(path) {
      return FileService.list(path).then((response) => {
        fileListCache[path] = response
        return angular.copy(response)
      })
    }

    function refresh(event) {
      return loadFileListFromServer(vm.cwd).then((list) => {
        markUnreadFiles(list)
        vm.list = list
      })
    }

    function updateCwd(path) {
      vm.cwd = path || ''
      vm.breadcrumbs = generateBreadcrumbs()
    }

    function markUnreadFiles(list) {
      var unreadFiles = $scope.$root.unreadFiles || []
      list.forEach((entry) => {
        var path = (entry.dir ? entry.dir + '/' : '') + entry.name + (entry.type == 'dir' ? '/' : '')
        entry.unread = unreadFiles.filter((file) => file.startsWith(path)).length > 0
      })
    }
  }
})()
