;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.administration').factory('UserAdminService', UserAdminService)

  function UserAdminService(CONFIG, $resource) {
    var service = {
      getUsers: getUsers,
      getUser: getUser,
      editUser: editUser,
      deleteUser: deleteUser,

      getRoles: getRoles,
      getRole: getRole,
      newRole: newRole,

      getPermissions: getPermissions,
    }

    var User = $resource(CONFIG.API_URL + '/users/:memberId', { memberId: 0 })
    var Role = $resource(CONFIG.API_URL + '/users/roles/:roleId', { roleId: '@roleId' })
    var Permission = $resource(CONFIG.API_URL + '/users/permissions/:permissionId')

    return service

    function getUsers(customerId) {
      return User.query({ customerId }).$promise
    }

    function getUser(memberId) {
      return User.get({ memberId }).$promise
    }

    function editUser(memberId, user) {
      return User.save({ memberId }, user).$promise
    }

    function deleteUser(memberId) {
      return User.delete({ memberId }).$promise
    }

    function getRoles() {
      return Role.query().$promise
    }

    function getRole(roleId) {
      return Role.get({ roleId }).$promise
    }

    function newRole(data) {
      return new Role(data)
    }

    function getPermissions() {
      return Permission.query().$promise
    }
  }
})()
