;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.administration')
    .controller('RoleListController', RoleListController)
    .controller('RoleDetailController', RoleDetailController)
    .controller('RoleFormController', RoleFormController)

  function RoleListController($mdDialog, roles) {
    var vm = this
    vm.deleteRole = deleteRole
    vm.roles = roles

    function deleteRole(event, role) {
      var confirm = $mdDialog
        .confirm()
        .title('Rolle löschen?')
        .htmlContent('Rolle <q>' + role.name + '</q> löschen?')
        .ok('Ja')
        .cancel('Nein')
        .targetEvent(event)
      return $mdDialog
        .show(confirm)
        .then(() => role.$delete())
        .then(() => {
          var idx = vm.roles.indexOf(role)
          if (idx > -1) {
            vm.roles.splice(idx, 1)
          }
        })
    }
  }

  function RoleDetailController(roles, role) {
    var vm = this
    vm.role = role
    vm.hierarchy = [buildHierarchy(role)]
    vm.orderByExpression = ['customer.customerId', 'member.lastname', 'member.forename']

    function buildHierarchy(role) {
      role = roles.find((r) => r.roleId == role.roleId)
      return Object.assign({}, role, {
        title: role.description || role.name,
        children: (role.children || []).map((r) => buildHierarchy(r)),
      })
    }
  }

  function RoleFormController($state, permissions, roles, role) {
    var vm = this
    vm.mode = role.roleId ? 'edit' : 'new'
    vm.queryPermissions = queryPermissions
    vm.reset = reset
    vm.roles = roles
    vm.save = save
    vm.transformPermissionChip = transformPermissionChip

    reset()

    function queryPermissions(searchText) {
      searchText = searchText.toLowerCase()
      return permissions.filter((permission) => !searchText || permission.name.toLowerCase().indexOf(searchText) > -1)
    }

    function reset(event) {
      vm.role = angular.copy(role)
      if (!vm.role.children) {
        vm.role.children = []
      }
      if (!vm.role.permissions) {
        vm.role.permissions = []
      }
    }

    function save(event) {
      var role = angular.copy(vm.role)
      role.children = role.children ? role.children.map((child) => child.roleId) : undefined
      role.permissions = role.permissions ? role.permissions.map((permission) => permission.name) : undefined

      var promise = role.roleId ? role.$update() : role.$create()
      return promise.then((editRole) =>
        $state.go('administration.userroles.details', { roleId: editRole.roleId }, { reload: true })
      )
    }

    function transformPermissionChip(chip) {
      return angular.isObject(chip) ? chip : { name: chip }
    }
  }
})()
