;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.administration')
    .controller('UserListController', UserListController)
    .controller('UserEditController', UserEditController)

  function UserListController(
    $filter,
    $mdDialog,
    $scope,
    $state,
    $transition$,
    dataCustomers,
    LocalStorageService,
    UserAdminService,
    UserService
  ) {
    $scope.customers = dataCustomers

    if ($transition$.params().customerId) {
      LocalStorageService.set('customer', $transition$.params().customerId)
    }

    $scope.customerId = LocalStorageService.get('customer') || UserService.getCustomerId()

    $scope.filter = {
      user: { state: true },
      member: angular.copy($transition$.params().filter) || {},
      clear: function () {
        this.user = this.member = {}
        this.user.state = true
      },
      updateUsers: function () {
        $scope.users = $filter('filter')($scope.allUsers, (user) => {
          var show = true
          var member = user.member
          show = show && (!this.user.id || user.userId === parseInt(this.user.id))
          show = show && (!this.user.state || user.state === 'active')
          show = show && (!this.member.id || member.memberId === parseInt(this.member.id))
          show = show && (!this.member.name || member.name.toLowerCase().indexOf(this.member.name.toLowerCase()) > -1 || (member.addition || '').toLowerCase().indexOf(this.member.name.toLowerCase()) > -1)
          show = show && (!this.member.email || member.email.toLowerCase().indexOf(this.member.email.toLowerCase()) > -1)
          return show
        })
        $state.go('.', { filter: this.getStateParams() })
      },
      getStateParams: function () {
        var params = angular.copy(this.member)
        Object.keys(params).forEach((key) => {
          if (!params[key]) {
            delete params[key]
          }
        })
        if (angular.equals(params, {})) {
          return undefined
        }
        return params
      },
    }

    $scope.customerSelect = function () {
      $scope.loadUsers()
    }

    $scope.loadUsers = function () {
      UserAdminService.getUsers($scope.customerId).then((users) => {
        $scope.allUsers = users
        $scope.filter.updateUsers()
      })
    }

    $scope.userDelete = function (event, user) {
      var confirm = $mdDialog
        .confirm()
        .targetEvent(event)
        .title('Benutzer löschen?')
        .htmlContent(
          '<center>Soll der Benutzer-Zugang für<br><br><b>' +
            user.member.name +
            '</b><br><br>wirklich gelöscht werden?</center>'
        )
        .ok('Ja')
        .cancel('Nein')

      return $mdDialog.show(confirm).then(() => {
        return UserAdminService.deleteUser(user.member.memberId).finally((response) => {
          $scope.loadUsers()
          return response
        })
      })
    }

    $scope.memberEdit = function (event, user) {
      $state.go('management.customers.members.edit', {
        filter: $scope.filter.getStateParams(),
        customerId: $scope.customerId,
        memberId: user.member.memberId,
      })
    }
  }

  function UserEditController(
    $filter,
    $mdDialog,
    $scope,
    $state,
    dataDebitors,
    dataRoles,
    dataStores,
    dataStoregroups,
    dataSystems,
    dataUser,
    helperService,
    ClipboardService,
    Notification,
    PasswordService,
    UserAdminService
  ) {
    var self = this
    dataUser.roles = dataUser.roles.map((role) => {
      return role.roleId
    })
    dataUser.systems = dataUser.systems.map((system) => {
      return system.systemId
    })
    dataUser.storegroups = dataUser.storegroups.map((storegroup) => {
      return storegroup.storegroupId
    })
    var userBackup = angular.copy(dataUser)

    $scope.formTitle =
      'Benutzer-Zugang bearbeiten :: ' +
      dataUser.member.forename +
      ' ' +
      dataUser.member.lastname +
      (dataUser.member.addition !== null ? ' (' + dataUser.member.addition + ')' : '')
    $scope.user = dataUser
    $scope.user.state = dataUser.state === 'active'
    $scope.roles = dataRoles
    $scope.systems = dataSystems
    $scope.setRefUser = function () {
      helperService.setRefUser($scope.user, $scope.systems)
    }
    $scope.selectUserSystem = function () {
      helperService.selectUserSystem($scope.user)
    }
    $scope.debitorsAll = dataDebitors
    $scope.debitors = dataDebitors
    $scope.storegroups = dataStoregroups
    $scope.storesAll = dataStores
    $scope.stores = dataStores
    $scope.storeAssignmentVariant = dataUser.debitors.length > 0 ? 'byDebitor' : 'byStore'

    $scope.generatePassword = function () {
      var password = PasswordService.generateRandomPassword(8, { incUpper: false, incSymbols: false })
      $scope.user.password = password
      $scope.user.passwordVerify = password
      var message = '<p><b>Passwort:</b> <i>' + password + '</i></p>'
      if (ClipboardService.copy(password)) {
        message += '<p>In die Zwischenablage kopiert!</p>'
      }
      Notification.info(message)
    }

    // init array with selected store IDs
    self.initStoresSelected = function () {
      $scope.debitorsSelected = []
      $scope.storesSelectedAllow = []
      $scope.storesSelectedDeny = []

      for (var debitor of dataUser.debitors) {
        $scope.debitorsSelected.push(debitor.debitorId)
      }

      for (var allow of dataUser.storesAllow) {
        $scope.storesSelectedAllow.push(allow.storeId)
      }

      for (var deny of dataUser.storesDeny) {
        $scope.storesSelectedDeny.push(deny.storeId)
      }
    }

    // reset data in previous tab if the new one becomes active
    $scope.changeStoreAssignmentVariant = function (tabIndexNew) {
      var tabIndexOld = $scope.storeAssignmentVariant

      if (tabIndexOld !== tabIndexNew) {
        var dialogTitle = 'Store-Zuordnung ändern'
        var dialogText =
          'Achtung: beim Ändern der Store-Zuordnungsmethode werden die alten/vorherigen Werte gelöscht! Sollen die bisherigen Store-Zuordnungen entfernt werden?'
        var confirm = $mdDialog
          .confirm()
          .title(dialogTitle)
          .ariaLabel(dialogTitle)
          .textContent(dialogText)
          .ok('OK')
          .cancel('Abbrechen')

        $mdDialog.show(confirm).then(
          () => {
            self.initStoresSelected()

            switch (tabIndexNew) {
              case 0:
                break
              case 1:
                $scope.user.storegroups = undefined
                break
            }
            $scope.storeAssignmentVariant = tabIndexNew
          },
          () => {
            $scope.storeAssignmentVariant = tabIndexOld
          }
        )
      }
    }

    $scope.updateStores = function (term) {
      if (!term) {
        $scope.stores = $scope.storesAll
        return
      }
      $scope.stores = $filter('filter')($scope.storesAll, (store) => {
        return (
          store.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          (store.foreignIdent || '').toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          (store.foreignIdent || '').toLowerCase().replace(' ', '').indexOf(term.toLowerCase()) > -1
        )
      })
    }

    $scope.updateDebitors = function (term) {
      if (!term) {
        $scope.debitors = $scope.debitorsAll
        return
      }
      $scope.debitors = $filter('filter')($scope.debitorsAll, (debitor) => {
        var show = debitor.debitorId.toString() === term
        for (var i = 0, len = debitor.debitorStoreAssociations.length; i < len; i++) {
          var store = debitor.debitorStoreAssociations[i].store
          show =
            show ||
            store.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            (store.foreignIdent || '').toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            (store.foreignIdent || '').toLowerCase().replace(' ', '').indexOf(term.toLowerCase()) > -1
        }
        return show
      })
    }

    $scope.addDebitor = function (debitorId) {
      if ($scope.debitorsSelected.indexOf(debitorId) === -1) {
        $scope.debitorsSelected.push(debitorId)
      }
    }

    $scope.removeDebitor = function (debitorId) {
      var debitorsSelectedNew = []
      for (var debitor of $scope.debitorsSelected) {
        if (debitor !== debitorId) {
          debitorsSelectedNew.push(debitor)
        }
      }
      $scope.debitorsSelected = debitorsSelectedNew
    }

    $scope.addStoreAllow = function (storeId) {
      if ($scope.storesSelectedAllow.indexOf(storeId) === -1) {
        $scope.storesSelectedAllow.push(storeId)
      }
    }

    $scope.removeStoreAllow = function (storeId) {
      var storesSelectedAllowNew = []
      for (var allow of $scope.storesSelectedAllow) {
        if (allow !== storeId) {
          storesSelectedAllowNew.push(allow)
        }
      }
      $scope.storesSelectedAllow = storesSelectedAllowNew
    }

    $scope.addStoreDeny = function (storeId) {
      if ($scope.storesSelectedDeny.indexOf(storeId) === -1) {
        $scope.storesSelectedDeny.push(storeId)
      }
    }

    $scope.removeStoreDeny = function (storeId) {
      var storesSelectedDenyNew = []
      for (var deny of $scope.storesSelectedDeny) {
        if (deny !== storeId) {
          storesSelectedDenyNew.push(deny)
        }
      }
      $scope.storesSelectedDeny = storesSelectedDenyNew
    }

    $scope.cancel = function () {
      $state.go('administration.users.details', { memberId: userBackup.member.memberId })
    }

    $scope.reset = function () {
      $scope.user = angular.copy(userBackup)
      $scope.user.state = userBackup.state === 'active'
      self.initStoresSelected()
      $scope.userForm.$setPristine()
      $scope.userForm.$setUntouched()
    }

    $scope.save = function () {
      $scope.user.storeAssignmentVariant = $scope.storeAssignmentVariant
      // submit only the values for the current store-assignment variant
      if ($scope.storeAssignmentVariant === 'byStore') {
        $scope.user.debitors = []
        $scope.user.storegroups = $scope.user.storegroups === undefined ? [] : $scope.user.storegroups
        $scope.user.storesAllow = $scope.storesSelectedAllow
        $scope.user.storesDeny = $scope.storesSelectedDeny
      } else if ($scope.storeAssignmentVariant === 'byDebitor') {
        $scope.user.debitors = $scope.debitorsSelected === undefined ? [] : $scope.debitorsSelected
        $scope.user.storegroups = []
        $scope.user.storesAllow = []
        $scope.user.storesDeny = []
      }

      UserAdminService.editUser($scope.user.member.memberId, $scope.user).then((user) => {
        if (user.userId !== null) {
          $state.go('administration.users', {}, { reload: true })
        }
      })
    }

    self.initStoresSelected()
  }
})()
