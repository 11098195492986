;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.files').service('FileService', FileService)

  function FileService(CONFIG, $resource, $rootScope, helperService) {
    this.list = list
    this.download = download
    this.updateUnreadFiles = updateUnreadFiles

    var FileList = $resource(CONFIG.API_URL + '/files/list/:directory')
    var FileDownload = $resource(CONFIG.API_URL + '/files/download/:path', null, { get: createDownloadAction() })
    var FileUnread = $resource(CONFIG.API_URL + '/files/unread', { hideGlobalSpinner: true })

    function list(directory) {
      return FileList.query({ directory }).$promise
    }

    function download(path) {
      return FileDownload.get({ path }).$promise.then((response) => {
        updateUnreadFiles()
        return response
      })
    }

    function createDownloadAction() {
      return {
        responseType: 'arraybuffer',
        transformResponse: helperService.data2download,
      }
    }

    function updateUnreadFiles() {
      $rootScope.loadingUnreadFiles = true
      return FileUnread.query()
        .$promise.then((response) => {
          $rootScope.unreadFiles = response
          updateUnreadBadge(response.length)
          $rootScope.$broadcast('UPDATE_UNREAD_FILES', $rootScope.unreadFiles)
        })
        .finally(() => {
          delete $rootScope.loadingUnreadFiles
        })
    }
  }

  function updateUnreadBadge(count) {
    var navItem = document.querySelector('.nav-item-files')
    if (!navItem) {
      return
    }

    var nameNode = Array.from(navItem.childNodes).find(
      (node) => node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
    )
    if (!nameNode) {
      return
    }

    var badge = nameNode.nextSibling
    if (!badge || !badge.classList || !badge.classList.contains('badge')) {
      badge = document.createElement('span')
      badge.classList.add('badge')
      nameNode.after(badge)
    }

    badge.textContent = count
    if (count) {
      badge.style.removeProperty('display')
    } else {
      badge.style.display = 'none'
    }
  }
})()
