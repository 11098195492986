(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.index', ['catalog.sellout'])
    .config(function ($stateProvider, $urlRouterProvider, moduleRegisterProvider) {
      $stateProvider.state('index', {
        url: '/',
        views: {
          '@': {
            templateUrl: 'src/index/views/index.html',
            controller: 'IndexController',
          },
        },
        resolve: {
          userCustomer: function (UserService, CustomerService) { return CustomerService.getCustomer(UserService.getCustomerId()).$promise },
          userRoles: function (UserService) { return UserService.getRoles() },
        },
      }).state('error', {
        url: '/error',
        views: {
          '@': { templateUrl: 'src/index/views/error.html' },
        },
      })

      $urlRouterProvider.when('', '/')
      $urlRouterProvider.otherwise('/error')

      moduleRegisterProvider.add({
        name: 'Startseite',
        url: 'index',
        materialIcon: 'home',
        accessRoles: ['user', '^minimal-ui'],
      })
    })
    .controller('IndexController', function ($scope, userCustomer, userRoles, CustomerService) {
      $scope.customer = userCustomer

      var isOttomatik = $scope.customer.customerId === 999999999
      if (isOttomatik) {
        return
      }

      CustomerService.getStores($scope.customer.customerId).$promise
        .then(function (stores) {
          $scope.stores = stores

          if (stores.length === 1) {
            $scope.paramsSubAcc = {
              customerId: $scope.customer.customerId,
              storeId: $scope.stores[0].storeId,
              dateFrom: moment().startOf('month').subtract(1, 'month').format('L'),
              dateTo: moment().startOf('month').format('L'),
            }
          }
        })

      $scope.showBonuspoints = userRoles.includes('statistics_bonuspoints')
      $scope.showCatalogSellout = userRoles.includes('catalog_sellout_index')
      $scope.showFiles = userRoles.includes('files')
      $scope.showStatistic = userRoles.includes('statistics_orders')
      $scope.showSubscribers = userRoles.includes('statistics_subacc')
    })
}())
